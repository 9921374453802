exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-coming-soon-tsx": () => import("./../../../src/pages/coming-soon.tsx" /* webpackChunkName: "component---src-pages-coming-soon-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-services-counseling-tsx": () => import("./../../../src/pages/services/counseling.tsx" /* webpackChunkName: "component---src-pages-services-counseling-tsx" */),
  "component---src-pages-services-dmhp-tsx": () => import("./../../../src/pages/services/dmhp.tsx" /* webpackChunkName: "component---src-pages-services-dmhp-tsx" */),
  "component---src-pages-services-enneagram-tsx": () => import("./../../../src/pages/services/enneagram.tsx" /* webpackChunkName: "component---src-pages-services-enneagram-tsx" */),
  "component---src-pages-services-journaling-tsx": () => import("./../../../src/pages/services/journaling.tsx" /* webpackChunkName: "component---src-pages-services-journaling-tsx" */),
  "component---src-pages-services-talent-tsx": () => import("./../../../src/pages/services/talent.tsx" /* webpackChunkName: "component---src-pages-services-talent-tsx" */)
}

